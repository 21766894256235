<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
  >
    <el-table
      ref="multipleTable"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      border
      :data="tableData"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column align="center" label="管理员名称" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.adminUserNickname }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="创建时间"
        :formatter="dateFormat"
        prop="createTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
        fixed="right"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      layout="total, sizes, prev, pager, next"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </el-dialog>
</template>
          
          <script>
import { update } from "@/api/bumen";

import util from "@/common/utils/util.js";
import { getglyList, DELETE } from "@/api/bumen";

export default {
  name: "TableEdit",
  data() {
    return {
      tableData: [],
      total: 0,
      loading: false,
      options1: [
        {
          id: 1,
          creClasName: "政务贵宾",
        },
        {
          id: 2,
          creClasName: "政务嘉宾",
        },
        {
          id: 3,
          creClasName: "行业贵宾",
        },
        {
          id: 4,
          creClasName: "行业嘉宾",
        },
      ], //状态
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        preUserType: "", //变更前
        aftUserType: "", //变更后
        id: "", //当前项的id
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
      },
      queryForm: {
        workGroupId: "",
        pageNum: 1,
        pageSize: 10,
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    handleDelete(row) {
      let loadingDiv;
      this.$confirm(
        "是否确认删除疫情管理员:" +
          row.adminUserNickname +
          "(疫情管理员名称:" +
          row.adminUserNickname +
          ")",
        "删除确认"
      )
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return DELETE({
              workGroupId: row.workGroupId,
              adminUserId: row.adminUserId,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.dialogFormVisible = false;
              this.$emit("fetch-data");
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },
    handleCurrentChange(pageNum) {
      this.queryForm.pageNum = pageNum;
      this.getDataList();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.getDataList();
    },
    dateFormat(row, column) {
      var date = row[column.property];
      console.log(date);
      if (date == undefined) {
        return "";
      }
      return this.timestampToTime(date);
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    getDataList() {
      const loadingDiv = util.showLoading();
      getglyList(this.queryForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;

            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },

    showEdit(row) {
      this.queryForm.workGroupId = row.id;
      this.title = "查看疫情管理员";
      this.dialogFormVisible = true;
      this.getDataList();
    },
    close() {
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "修改中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = update;

          func(this.form)
            .then((res) => {
              let title = "修改";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.close();
                  this.$emit("fetch-data");
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
  },
};
</script>
          