<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item>
              <!-- <el-button type="primary" size="small" @click="showAddDialogForm"
                >新增部门</el-button
              > -->
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>

      <el-table-column prop="name" label="部门名称"> </el-table-column>
      <!-- <el-table-column label="管理员详情" show-overflow-tooltip min-width="120">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="glEdit(scope.row)"
            >所属管理员</el-button
          >
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="createTime" label="创建时间"> </el-table-column> -->
      <el-table-column
        align="center"
        label="创建时间"
        :formatter="dateFormat"
        prop="createTime"
        show-overflow-tooltip
      />
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <bmgl ref="bmgl" @fetch-data="getDataList" />
    <edit ref="edit" @fetch-data="getDataList" />
    <bangding ref="bangding" @fetch-data="getDataList" />
    <glyxq ref="glyxq" @fetch-data="getDataList" />
  </div>
</template>
    
    <script>
import util from "@/common/utils/util.js";
import bmgl from "./model/add.vue";
import edit from "./model/edit.vue";
import glyxq from "./model/glyxq.vue"; //所属管理员

import bangding from "./model/bangding.vue";

import { add, getList, update, doDELETE } from "@/api/bumen";

export default {
  name: "huodongguanli",
  components: { bmgl, edit, bangding, glyxq },

  data() {
    return {
      handlerUserList: [],

      options1: [
        {
          id: 3,
          creClasName: "审核通过",
        },
        {
          id: 4,
          creClasName: "审核不通过",
        },
      ],
      form: {
        checkValue: "",
        failCause: "",
      }, //选择审核状态
      actorScore: "", //当前状态
      value1: "",
      total: 0,
      pageSize: 10,
      userList: [],
      userTypeList: [],
      currentPage: 1,
      tableData: [],
      rules: {
        process: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        name: [{ required: true, trigger: "blur", message: "请输入部门名称" }],
      },
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {},
      editRules: {
        title: [{ required: true, message: "请输入任务标题", trigger: "blur" }],
        endTime: [
          { required: true, message: "请选择截止时间", trigger: "blur" },
        ],
      },
      loadingDiv: null,
      searchForm: {
        pageSize: 10,
        pageNum: 1,
      },
      roleList: [],
      yyCompanyList: [],
      frontUserList: [],
    };
  },
  mounted() {
    this.getDataList();

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(val);
      this.pageSize = val;
      this.getDataList();
    },
    dateFormat(row, column) {
      var date = row[column.property];
      console.log(date);
      if (date == undefined) {
        return "";
      }
      return this.timestampToTime(date);
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    showAddDialogForm() {
      this.$refs["bmgl"].showEdit();
    },
    glEdit(row) {
      if (row.id) {
        this.$refs["glyxq"].showEdit(row);
      } else {
        this.$refs["glyxq"].showEdit();
      }
    },
    haEdit(row) {
      if (row.id) {
        this.$refs["edit"].showEdit(row);
      } else {
        this.$refs["edit"].showEdit();
      }
    },
    handlebangding(row) {
      if (row.id) {
        this.$refs["bangding"].showEdit(row);
      } else {
        this.$refs["bangding"].showEdit();
      }
    },
    close() {
      this.editForm = {};
    },
    submitForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "添加中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = add;
          if (this.editForm.id) {
            func = update;
          }
          func(this.editForm)
            .then((res) => {
              let title = "添加";
              if (this.editForm.id) {
                title = "修改";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.close();
                  this.getDataList();
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$confirm(
        "是否确认删除部门" + row.name + "(任务名称:" + row.name + ")",
        "删除确认"
      )
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("删除中...");
            return doDELETE({ id: row.id });
          }
        })
        .then((res) => {
          loadingDiv.close();
          if (res.code == 0) {
            this.$alert(res.msg, "删除失败", {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, "删除成功", {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then((res) => {
              this.editFormVisible = false;
              this.getDataList();
            });
          }
        })
        .catch((res) => {
          if (loadingDiv) {
            loadingDiv.close();
          }
        });
    },

    getDataList() {
      const loadingDiv = util.showLoading();

      getList(this.searchForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;

            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList();
    },
    handleCurrentChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getDataList();
    },
  },
};
</script>
    
    <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
    <style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
.fengmiantu {
  width: 50px;
  height: 50px;
}
</style>
    