<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="选择管理员" prop="adminUserId">
        <el-select
          v-model="form.adminUserId"
          clearable
          placeholder="请选择管理员"
        >
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.nickname"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
      
      <script>
import { genggaitwo } from "@/api/bumen";
import util from "@/common/utils/util.js";
import { getParticipateListwo } from "@/api/bumen";

export default {
  name: "TableEdit",
  data() {
    return {
      loading: false,
      options1: [], //状态
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        workGroupId: "",
        adminUserId: "", //选择的部门的id
      },
      rules: {
        adminUserId: [
          {
            required: true,
            message: "请选择绑定的疫情管理员",
            trigger: "change",
          },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    getDataList() {
      const loadingDiv = util.showLoading();

      getParticipateListwo()
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            this.options1 = res.data;
            console.log(res);
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },

    showEdit(row) {
      this.actorScore = row.workGroupName;

      this.form.workGroupId = row.id;
      this.title = "绑定疫情管理员";
      this.dialogFormVisible = true;
      this.getDataList();
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "绑定中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = genggaitwo;

          func(this.form)
            .then((res) => {
              let title = "绑定疫情管理员";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
                loadingDiv.close();
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.dialogFormVisible = false;
                  this.$emit("fetch-data");
                  loadingDiv.close();
                });
              }
              this.loading = false;
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
  },
};
</script>
      