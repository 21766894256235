<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :inline="true" :model="form" :rules="rules">
      <el-form-item label="部门名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入部门名称"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
        
        <script>
import { update } from "@/api/bumen";

import util from "@/common/utils/util.js";
import { getParticipateList } from "@/api/bumen";

export default {
  name: "TableEdit",
  data() {
    return {
      loading: false,
      options1: [
        {
          id: 1,
          creClasName: "政务贵宾",
        },
        {
          id: 2,
          creClasName: "政务嘉宾",
        },
        {
          id: 3,
          creClasName: "行业贵宾",
        },
        {
          id: 4,
          creClasName: "行业嘉宾",
        },
      ], //状态
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        preUserType: "", //变更前
        aftUserType: "", //变更后
        id: "", //当前项的id
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    getDataList() {
      const loadingDiv = util.showLoading();

      getParticipateList()
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            console.log(res);
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },

    showEdit(row) {
      this.form.id = row.id;
      this.form = row;
      this.title = "修改部门";
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "修改中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = update;

          func(this.form)
            .then((res) => {
              let title = "修改";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.editFormVisible = false;
                  this.close();
                  this.$emit("fetch-data");
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
  },
};
</script>
        